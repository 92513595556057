<template>

  <!-- Container principal -->
  <div class="row col-12 vh-md-75 vh-xl-100">
    <!-- Loading -->
    <loading v-model:active="isLoading"
      :can-cancel="true"
      :is-full-page="fullPage"/>

    <!-- Container Izquierda -->
    <div class="col-12 bg-white p-4 p-md-5 border">
      
      <!-- <h2>{{ storeCounter.name }}</h2> -->

      <!-- Tabla de muestras -->
      <h4 class="text-start pb-2">Listado de muestras</h4>

      <!-- <div class="col-4 text-start mb-3">
        <label class="bold">Nombre Cliente A Buscar</label>
        <input type="text" class="form-control" v-model="nombre" @input="mostrarClientes">
      </div> -->

      <div class="text-start p-2 border rounded tablaScrollClientes">
        <table class="table table-striped">
          <thead>
            <tr>
              <th scope="col">ID Muestra</th>
              <th scope="col">Titular</th>
              <th scope="col">S/Refrencia</th>
              <!-- Dropdown for filtering -->
              <th scope="col"> 
                <label>Muestreador</label>
                <div class="form-group input-group">
                  <select class="form-select rounded" v-model="filtroSeleccionado" @change="filtrarMuestrasMuestreador">
                      <option value="">Mostrar todas</option>
                      <!-- Antes en :value="muestra.muestreador" pasaba el nom, ara el id de mue_m -->
                      <option v-for="muestra in elementosFiltrados" :key="muestra.id" :value="muestra.mue_m">{{ muestra.muestreador }}</option>
                  </select>
                </div>
              </th>

            </tr>
          </thead>
          <tbody>
            <tr v-for="muestra in muestras" :key="muestra.id">
              <td> Numero Muestra: {{ muestra.num_mue }} </td>
              <td> <router-link :to="'/muestra/modificarpresupuesto/'+muestra.clt+'/'+muestra.name+'/'+muestra.id+'/'" class="text-verde"> {{ muestra.name }}</router-link> </td>
              <td> {{ muestra.ref_clt }} </td>
              <td> {{ muestra.muestreador }} </td>
            </tr>
          </tbody>
        </table>
      </div>

    </div>

  </div>
</template>

<script>
// import axios from "axios";
import auth from '../api/auth';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/css/index.css';
import { useMyStore } from '@/api/myStore'

export default {
  name: "Home",
  props: {
    msg: String,
  },
  computed: {
    userLogged() {
      return auth.getUserLogged();
    }
  },
  data() {
    return {
      isLoading: false, //Loading
      fullPage: true, //Loading
      muestras: null,
      info: null,
      articulos: null,
      articulosGlobal: [],
      storeCounter: useMyStore(),
      nombre: '',
      elementosFiltrados: [],
    }
  },
  components: {
    Loading
  },
  methods: {
    async obtenerMuestras() {
      this.isLoading = true; //Loading

      var respuestaTomasMuestra = await auth.getObtenerTomasMuestra();
      //console.log("LAS TOMAS DE MUESTRA SON: ");
      //console.log(respuestaTomasMuestra.data);
      this.muestras = respuestaTomasMuestra.data.tom_mue;

      const elementosFiltrados = [];

      for (const element of this.muestras) {
        let idMuestreadorTemp = element.mue_m;
        let respuestaNombre = await auth.getMuestreador(idMuestreadorTemp);
        //console.log("ID MUESTREADOR " + idMuestreadorTemp);
        //console.log(respuestaNombre);
        
        //console.log("Longitud: " + respuestaNombre.data.usr_m.length);

        if (respuestaNombre.data.usr_m && respuestaNombre.data.usr_m.length > 0 ) {
          let nombreMuestreador = respuestaNombre.data.usr_m[0].name_dsc;
          if (nombreMuestreador.trim() !== "") {
            element.muestreador = nombreMuestreador; // Nombre muestreador

            //console.log(element);
            // Si el elemento pasa la condición, agrégalo al nuevo array
            elementosFiltrados.push(element);
            console.log("Añadido");
          }
        }
      }

      console.log("MUESTRAS FILTRADAS");
      this.elementosFiltrados = elementosFiltrados;

      console.log("ARRAY FINAL MUESTRAS FILTRADAS");
      console.log(this.elementosFiltrados);

      // Crear un conjunto para rastrear los números de mue_m existentes
      const numerosExistentes = new Set();

      // Filtrar elementos duplicados basados en mue_m
      const elementosUnicos = [];
      this.elementosFiltrados.forEach(elemento => {
        if (!numerosExistentes.has(elemento.mue_m)) {
          numerosExistentes.add(elemento.mue_m);
          elementosUnicos.push(elemento);
        }
      });

      this.elementosFiltrados = elementosUnicos;
 
      this.isLoading = false; //Loading

    },
    async filtrarMuestrasMuestreador() {
      this.isLoading = true; //Loading
      
      console.log("FILTRO SELECCIONADO ES MUE_M: ");
      console.log(this.filtroSeleccionado);

      if (this.filtroSeleccionado) { //Si seleciono un nombre entra aqui
        var respuestaTomasMuestra = await auth.getObtenerTomasMuestraPorMuestreador(this.filtroSeleccionado);
        this.muestras = respuestaTomasMuestra.data.tom_mue;

        const elementosFiltrados = [];

        for (const element of this.muestras) {
          // let idMuestreadorTemp = element.id;
          let respuestaNombre = await auth.getMuestreador(this.filtroSeleccionado);
          //console.log(respuestaNombre.data.usr_m[0].name_dsc);
          //console.log("El nombre del muestreador es: " + respuestaNombre.data);
          
          //console.log(element);
          if (respuestaNombre.data.usr_m && respuestaNombre.data.usr_m.length > 0 ) {
            let nombreMuestreador = respuestaNombre.data.usr_m[0].name_dsc;
            if (nombreMuestreador.trim() !== "") {
              element.muestreador = nombreMuestreador; // Nombre muestreador

              // Si el elemento pasa la condición, agrégalo al nuevo array
              elementosFiltrados.push(element);
            }
          }
        }

        // Filtrar el arreglo muestras basado en el filtroSeleccionado
        this.muestras = elementosFiltrados;

        this.isLoading = false; //Loading

      } else {
        // Si no se selecciona un muestreador, mostrar todas las muestras
        this.obtenerMuestras();
      }
    }
  },
  async mounted () {
    //MOUNTED ES LO QUE SE EJECUTA AL ENTRAR A UN COMPONENTE
    
    //COMPROBAR SI ESTA LOGUEADO, SI LO ESTA SE SI NO SE QUEDA EN LA PANTALLA DE LOGIN
    if (auth.getUserLogged && auth.getUserLogged() != undefined) {
      //console.log("Usuario actualmente logeado");  
    } else {
      this.$router.push("/login");
    }

    //TRY CATCH DEL AUTH.JS PARA LAS FUNCIONES
    try {
      //Obtenemos los presupuestos hechos del cliente
      this.obtenerMuestras();
   
      console.log("Muestras");
      console.log(this.muestras);

    } catch (error) {
      console.log(error);
    }

  }, //FIN DEL MOUNTED
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/*Nav */
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: white;
}

.text-verde {
  color: #008080;
}

/* Tablas */
.tablaScrollClientes {
  overflow-y: scroll;
  height: 70vh;
}

.tablaScrollInformes {
  overflow-y: scroll;
   height: 26vh;
}

.tablaScrollArticulos {
  overflow-y: scroll;
  height: 60.5vh;
}

/* Iconos */
::before {
    padding-bottom: 2%;
}

/* Quitar el row que inventa bootstrap */
.row {
  --bs-gutter-x: 0rem;
}
</style>
